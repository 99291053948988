import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home/', icon: 'Home' },
    { title: 'Library', url: '/library/', icon: 'library' },
    { title: 'New Pack', url: '/create/', icon: 'bulb' },
    { title: 'Tutorials', url: '/tutorials/', icon: 'film' },
    { title: 'Purchase', url: '/buy/', icon: 'cart' },
    { title: 'Register HERE', url: '/registration/', icon: 'star' },

  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}
}
