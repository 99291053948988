
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAEU_966qtv3cjtUbZpMetaF5iW0DgLKiQ",
    authDomain: "lingowise-ac00c.firebaseapp.com",
    databaseURL: "https://lingowise-ac00c-default-rtdb.firebaseio.com",
    projectId: "lingowise-ac00c",
    storageBucket: "lingowise-ac00c.appspot.com",
    messagingSenderId: "729302940404",
    appId: "1:729302940404:web:11612e6a6545cff2893ca1",
    measurementId: "G-X8Y6HYKS66"
  }
};